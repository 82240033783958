import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

export default function Chat() {
  const [success, setSuccess] = useState(false);
  const [text, setText] = useState("");
  const [textTemp, setTextTemp] = useState("");

  const [loading, setLoading] = useState(false);
  const [saveloading, setSaveloading] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showPromptList, setShowPromptList] = useState(false);
  const [promptsList, setPromptsList] = useState([]);
  const [tempPromptsList, setTempPromptsList] = useState([]);
  const [formvalues, setFormvalues] = useState({ name: "", description: "" })
  const [chatHistory, setChatHistory] = useState([{ role: "assistant", content: "Hello! How can I assist you today?" }]);
 
  useEffect(() => {
    listPrompts();
  }, [])


  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const ScrollToBottom = async(ms)=>{
    await sleep(ms);
    var outputContainer = document.querySelector('.output-container');
    outputContainer.scrollTop = outputContainer.scrollHeight;
  }
  /* const abortRequest = () => {
    console.log("welcome");
    controller.abort()
    console.log(controller);
  } */

  const setSearchText = (value) => {
    const newprompts = tempPromptsList.filter((prompt) => prompt.name.toLowerCase().includes(value.toLowerCase()) || prompt.description.toLowerCase().includes(value.toLowerCase()))
    setPromptsList(newprompts);
  }
  const deletePrompt = async (id) => {
    await axios({
      method: 'delete',
      url: 'https://k2bai.ezzysales.com/deleteprompt/' + id,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(async (response) => {
        setSuccess(true);
        listPrompts();
        await sleep(3000);
        setSuccess(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const listPrompts = async () => {
    await axios({
      method: 'get',
      url: 'https://k2bai.ezzysales.com/getprompts',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(async (response) => {
        setPromptsList(response.data);
        setTempPromptsList(response.data);

      })
      .catch(function (error) {

        console.log(error);
      });
  }

  const savePrompt = async () => {
    setSaveloading(true);
    await sleep(70);
    if (formvalues.name === "") {
      document.querySelector("#name").classList.add("error");
    } else {
      document.querySelector("#name").classList.remove("error");
    }
    if (formvalues.description === "") {
      document.querySelector("#description").classList.add("error");
    } else {
      document.querySelector("#description").classList.remove("error");
    }
    axios({
      method: 'post',
      url: 'https://k2bai.ezzysales.com/prompt',
      data: { name: formvalues.name, description: formvalues.description },
      headers: { 'Content-Type': 'application/json' },
    })
      .then(async (response) => {
        listPrompts();
        await sleep(500);
        setSaveloading(false);
        setShowPrompt(false);
        setShowPromptList(true);
        setFormvalues({ name: "", description: "" });
      })
      .catch(function (error) {
        setSaveloading(false);
        console.log(error);
      });


  };

  const handleSubmit = (promptText = "") => {
    
    
    if (text === "" && promptText === "") { 
      
      return false;
    }
    setLoading(true);
    setTextTemp("");
    
    setChatHistory([...chatHistory, { role: "user", content: (promptText === "" ? text : promptText) }])
    ScrollToBottom(500);
    axios({
      method: 'post',
      url: 'https://k2bai.ezzysales.com/',
      data: {text: (promptText === "" ? text : promptText) },
      headers: { 'Content-Type': 'application/json' },
     
    })
      .then(async (response) => {
        setLoading(false);
        document.querySelector(".animation").style.display = 'block';
        let splitcontent = response.data[0].message.content.split(" ")
        let wordcount = 0
        let mergecontent = "";
        while (wordcount < splitcontent.length) {
          await sleep(70);
          mergecontent += " " + splitcontent[wordcount];
          document.querySelector(".animation .content").innerHTML = mergecontent;
          wordcount++;
          ScrollToBottom(0);
        }
        document.querySelector(".animation").style.display = 'none';
        setChatHistory([...chatHistory, { role: "user", content: (promptText === "" ? text : promptText) }, { role: response.data[0].message.role, content: response.data[0].message.content }])
        setText("");
       
        document.querySelector("textarea").value = "";
        await sleep(50);
        document.querySelector(".animation .content").innerHTML = "";        
        ScrollToBottom(0);

      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  }

  const clearChat = () => { setChatHistory([{ role: "assistant", content: "Hello! How can I assist you today?" }]); setTextTemp(""); setText(""); document.querySelector("textarea").value = ""; document.querySelector("textarea").focus(); }

  return (
    <div className='wrapper'>
      {showPrompt && <div className='prompt-add'><div className="prompt-header"><i className="far fa-bookmark"></i> Save Prompt <i className="fa close" onClick={(e) => { setShowPrompt(false) }}>&#xf00d;</i></div>
        <form>
          <div ><label>Name</label>
            <br />
            <input type="text" id="name" required placeholder="Give your prompt template a short name" name="name" value={formvalues.name} onChange={(e) => { setFormvalues({ ...formvalues, [e.target.name]: e.target.value }); }} />
          </div>
          <div ><label>Description</label><br />
            <textarea required id="description" name="description" value={formvalues.description} placeholder="Your prompt template. Example: Summarize this article in 5 sentences, for a 12 year old." onChange={(e) => { setFormvalues({ ...formvalues, [e.target.name]: e.target.value }); }}>{formvalues.description}</textarea>
          </div><br />
          <div className='submit-wrapper'>{saveloading && <><span className='loading__dot'></span><span className='loading__dot'></span><span className='loading__dot'></span></>} {!saveloading && <span className='prompt-submit' onClick={savePrompt}><i className='fa fa-check'></i> Save</span>}</div>
        </form>
      </div>}

      {showPromptList && <div className='prompt-list'><div className="prompt-header"><i className="far fa-bookmark"></i> Your Saved Prompts <i className="fa close" onClick={(e) => { setShowPromptList(false) }}>&#xf00d;</i></div>
        {tempPromptsList.length > 0 && <p><input type="text" className='searchText' onChange={(e) => { setSearchText(e.target.value) }} placeholder='Search for your Prompt Title' /></p>}
        <ul>
          {promptsList.length < 1 && <li >No records found.</li>}
          {success && <li className='success'>Deleted successfully</li>}
          {promptsList.map((item, index) => {
            return (<li key={index} className='promptList'> <i className="trash fa fa-trash" onClick={(e) => { deletePrompt(item.id) }}></i><p className='name'><i onClick={async (e) => { setTextTemp(item.description); setText(item.description); }} className="prompt fa fa-light  fa-clone"></i>  {item.name} </p><p>{item.description} </p> </li>);
          })}

        </ul>
        <div className='add'> <span onClick={(e) => { setShowPrompt(true); setShowPromptList(false); }}>Add Prompt   </span></div>
      </div>}

      <div className='container'>

        {chatHistory.length > 0 && <ul className='output-container'>
          {chatHistory.map((item, index) => <li key={index} className={item.role}><p className='role'>{item.role === 'assistant' ? "Kevin" : "Ram"}</p>
            <p className='content'>{item.content}</p></li>)}

          <li className='animation assistant'><p className='role'>Kevin</p>
            <p className='content'></p></li>
          {loading === true && <p className='left'><span className='loading__dot'></span><span className='loading__dot'></span><span className='loading__dot'></span></p>}
        </ul>}

        <br />
        {chatHistory.length > 0 && <div className='newchat-wrapper'><span className='newchat' onClick={clearChat}><span className='far fa-comment'></span> Start a new chat</span></div>} <br />
        {/*loading && <div className='abort' onClick={(e)=>{
        abortRequest();
        }}>Stop Generating </div>*/}
        
        <div className='pre-templates'>
          <span className="prompt" onClick={(e) => { setShowPrompt(true); setShowPromptList(false); }}><label><i className="far fa-bookmark"></i></label>  </span>
          {promptsList.length > 0 && promptsList.map((item, index) => {
            if (index < 3) {
              return (<span key={index}><label onClick={async (e) => { handleSubmit(item.description); }}>{item.name}</label> <i onClick={async (e) => { setTextTemp(item.description); setText(item.description); }} className="prompt fa fa-light  fa-clone"></i></span>);
            }else{
              return false;
            }
          })}
          {promptsList.length > 2 && <span className="prompt" onClick={(e) => { setShowPromptList(true); setShowPrompt(false); }}><label>more</label></span>}</div>
        <div className='input-wrapper'>
          <textarea name="text" value={textTemp} className='textarea' onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (text !== "") { 
                handleSubmit("")  
            } else {
                alert("Prompt should not be empty")
            }
            }
          }} onChange={(e) => {
            setText(e.target.value.replace(/\n/g, ''));
            setTextTemp(e.target.value.replace(/\n/g, ''));

            if (e.target.scrollHeight > 80 && e.target.scrollHeight < 100) {
              document.querySelector('.textarea').style.height = e.target.scrollHeight + 'px';
              document.querySelector('.submit').style.bottom = e.target.scrollHeight / 5 + 'px';
            } if (text.length < 20) {
              document.querySelector('.textarea').style.height = '80px';
              document.querySelector('.submit').style.bottom = '15px';
            } 
          }} >{text}</textarea>
          <div onClick={(e) => {
            if (text !== "") { 
                handleSubmit("") 
            } else {
                alert("Prompt should not be empty")
            }
          }} className={loading?"submit disable":"submit"}> <span>&#10148;</span> </div>

        </div>

      </div> 

    </div>
  );
}